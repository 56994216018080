const getRequiredPropsFromBaseUrl = () => {
    let splitKey = process.env.REACT_APP_RUNWAY_DOMAIN;
    let fullUrl = (window.location.href);
    let routesAndQueryParams = fullUrl.split(splitKey)[1];
    let scanOption = '';
    let redirectUrl = '';
    let externalAuthKey = null;

    //Get Scan value
    if (routesAndQueryParams.toUpperCase().includes('SCAN')) {
        scanOption = `${routesAndQueryParams.toUpperCase().split('SCAN=')[1]}`;
        if(scanOption.includes('&')){
            scanOption = scanOption.split('&')[0];
        }

        scanOption = `scan=${scanOption}`.toLowerCase();
    }

    //Get redirect URL
    if (routesAndQueryParams.toUpperCase().includes('REDIRECTURL')) {
        redirectUrl = `${routesAndQueryParams.toUpperCase().split('REDIRECTURL=')[1]}`;
        if(redirectUrl.includes('&')){
            redirectUrl = redirectUrl.split('&')[0];
        }
        redirectUrl = `${redirectUrl}`.toLowerCase();
    }

    //Get externalAuthKey value
    if (routesAndQueryParams.toUpperCase().includes('EXTERNALAUTHKEY')) {
        let externalAuthKeyVal = `${routesAndQueryParams.toUpperCase().split('EXTERNALAUTHKEY=')[1]}`;
        if(externalAuthKeyVal.includes('&')){
            externalAuthKeyVal = externalAuthKeyVal.split('&')[0];
        }
        externalAuthKey = externalAuthKeyVal !== null ? externalAuthKeyVal : null;
    }

    if (!fullUrl.includes('#')) {
        fullUrl = fullUrl.replace(splitKey, `${splitKey}/#`);
    }

    //Get routes and query parems
    // if (routesAndQueryParams.includes('?')) {
    //     routesAndQueryParams = routesAndQueryParams.split('?')[0];
    // }
    return { returnUrl: routesAndQueryParams, loginURL: fullUrl, scanOption: scanOption, redirectUrl : redirectUrl,externalAuthKey: externalAuthKey }
}

const validateClientCode = () => {

    let loginData = {
        clientKey: '',
        returnUrl: '',
        scanOption: '',
        loginURL: '',
        redirectUrl: '',
        externalAuthKey: null
    };

    let baseUrl = (window.location.href);

    if (`${process.env.REACT_APP_IS_DEVMODE}` === 'true') {
        console.log("development mode enabled")
        loginData = getRequiredPropsFromBaseUrl();
        loginData.clientKey = 'CREAI';
    } else {
        var currentKey = baseUrl.split('//')[1].split('.')[0];
        if (`${process.env.REACT_APP_ENV}` !== '') {
            // If it is a preprod remove prorpod
            if (currentKey?.includes("preprod") || currentKey?.includes("dev")) {
                currentKey = currentKey.replace("preprod-", '');
                currentKey = currentKey.replace("dev-", '');
            }
        }

        //Get required information from base url. 
        loginData = getRequiredPropsFromBaseUrl();
        loginData.clientKey = currentKey.toUpperCase();
    }

    return loginData;
}

export { validateClientCode }